import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from '@emotion/styled';

import SEO from "../components/seo";
import EmailSubscribe from '../components/EmailSubscribe/EmailSubscribe';

import EmailSubscribe_data from '../../content/data/site/EmailSubscribe.yml';

function BlogPostTemplate({ data: { mdx: post } }) {
    return (
        <>
            <SEO title={post.frontmatter.title} />
            <CENTER>
                <H1>{post.frontmatter.title}</H1>
                <BYLINE>{post.frontmatter.date}</BYLINE>
                <IMAGE fluid={post.frontmatter.hero.sharp.fluid} alt="" />
                <CONTENT><MDX>{post.body}</MDX></CONTENT>
                <EmailSubscribe data={EmailSubscribe_data} showHeader={false} />
            </CENTER>
        </>
    );
}

export const query = graphql`
    query($slug: String!) {
        mdx(fields: { titleSlug: { eq: $slug } }) {
            frontmatter {
                author
                date(formatString: "MMMM DD, YYYY")
                excerpt
                title
                hero {
                    sharp: childImageSharp {
                        fluid(maxWidth: 800, maxHeight: 350) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            body
        }
    }
`;

const CENTER = styled('main')`
    width: 100vw;
    margin: 0 auto;
    padding: 80px 0;
    background: #FFF;

    // image switches to fullscreen
    @media (max-width: 500px) {
        width: 100%;
    }
`;

const H1 = styled('h1')`
    width: 60%;
    margin: 0 auto;
    text-align: center;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

    @media (max-width: 800px) {
        width: 80%;
    }

    @media (max-width: 400px) {
        font-size: 20px;
    }
`;

const BYLINE = styled('p')`
    text-align: center;
`;

const IMAGE = styled(Img)`
    display: block;
    height: 280px;
    max-width: 60%;
    margin: 0 auto 50px;
    background-size: cover;
    object-fit: cover;
    background-position-x: center;
    background-position-y: center;
    flex-shrink: 0;
    border-radius: 30px;
`;

const CONTENT = styled('div')`
    width: 60%;
    margin: 0 auto;

    @media (max-width: 800px) {
        width: 80%;
    }
`;

const MDX = styled(MDXRenderer)`
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
`;

export default BlogPostTemplate;